import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { Divider } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import moment from 'moment';

import DefaultLayout from 'layouts/DefaultLayout';
import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_INVITE from 'graphql/queries/fetchInvite.graphql';

import theme from './theme.scss';

type VisitorQueryResponse = {
  visitor: {
    id: string;
    name: string;
    description: string;
    visitingFrom: string;
    phoneNumber: string;
    email: string;
    visitTime: string;
    otp: number;
    status: string;
    organization: {
      id: string;
      name: string;
      address: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        country: string;
        pincode: string;
      };
    };
  };
};

type VisitorQueryVariables = {
  id: string;
  organizationId: string;
};

const Invite: FC = () => {
  const { visitorId, organizationId } = useParams();

  const { loading, error, data } = useQuery<VisitorQueryResponse, VisitorQueryVariables>(
    FETCH_INVITE,
    {
      variables: {
        id: visitorId ? visitorId : '',
        organizationId: organizationId ? organizationId : '',
      },
    }
  );

  if (loading) return <Loader type="screen" />;

  if (error || !data || !data.visitor) return <ErrorDisplay type="screen" />;

  const visitor = data.visitor;

  return (
    <DefaultLayout>
      <div className={theme.invite}>
        <div className={theme.content}>
          <h1>
            Your invite to <br />
            <span className={theme.organizationName}>{visitor.organization.name}</span> on <br />
            <span className={theme.visitTime}>
              {moment(visitor.visitTime).format('dddd, DD MMM YYYY')}.
            </span>
          </h1>
          <Divider />
          <h2>Meeting</h2>
          <div className={theme.information}>
            <div className={theme.field}>
              <p className={theme.label}>COMPANY NAME</p>
              <p className={theme.value}>{visitor.organization.name}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>LOCATION</p>
              <p
                className={theme.value}
              >{`${visitor.organization.address.line1} ${visitor.organization.address.line2}, ${visitor.organization.address.city}, ${visitor.organization.address.state}, - ${visitor.organization.address.pincode}`}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>PURPOSE / DESCRIPTION</p>
              <p className={theme.value}>{visitor.description}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>APPOINTMENT TIME</p>
              <p className={theme.value}>
                {moment(visitor.visitTime).format('HH:mm a, DD MMMM YYYY')}
              </p>
            </div>
          </div>
          <Divider />
          <h2>Visitor</h2>
          <div className={theme.information}>
            <div className={theme.field}>
              <p className={theme.label}>NAME</p>
              <p className={theme.value}>{visitor.name}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>VISITING FROM</p>
              <p className={theme.value}>{visitor.visitingFrom}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>PHONE NUMBER</p>
              <p className={theme.value}>{visitor.phoneNumber}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>EMAIL</p>
              <p className={theme.value}>{visitor.email}</p>
            </div>
          </div>
        </div>
        <div className={theme.qrcode}>
          <QRCode value={visitor.id} size={256} />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Invite;
