import React, { FC, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { SingleDatePicker } from 'react-dates';

import theme from './theme.scss';

const propsForDatePicker = {
  numberOfMonths: 1,
  displayFormat: 'DD MMMM YYYY',
  hideKeyboardShortcutsPanel: true,
};

type DateTimePickerProps = {
  value: any;
  onValueChange: (any) => void;
};

const DateTimePicker: FC<DateTimePickerProps> = ({ value, onValueChange }) => {
  const givenDateTime = value;

  const [focused, setFocus] = useState(false);
  const [hour, setHour] = useState<number>(givenDateTime.hour());
  const [minutes, setMinutes] = useState<number>(givenDateTime.minutes());

  function range(start, end) {
    return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
  }

  return (
    <div className={theme.dateTimePicker}>
      <SingleDatePicker
        {...propsForDatePicker}
        id="date_input"
        date={givenDateTime}
        focused={focused}
        onDateChange={date => onValueChange(date.set({ h: hour, m: minutes, s: 0 }))}
        onFocusChange={({ focused }) => setFocus(focused)}
      />
      <Dropdown
        className={theme.timePicker}
        value={hour}
        selection
        options={range(0, 23).map((hour, i) => ({
          key: i,
          text: ('0' + hour).slice(-2),
          value: hour,
        }))}
        onChange={(e, { value }) => {
          if (!value || typeof value !== 'number') return;
          onValueChange(givenDateTime.set({ h: value }));
          setHour(value);
        }}
      />
      <span className={theme.timeSeparator}>:</span>
      <Dropdown
        className={theme.timePicker}
        value={minutes}
        selection
        options={range(0, 3).map((minutes, i) => ({
          key: i,
          text: ('0' + minutes * 15).slice(-2),
          value: minutes * 15,
        }))}
        onChange={(e, { value }) => {
          if (!value || typeof value !== 'number') return;
          onValueChange(givenDateTime.set({ m: value }));
          setMinutes(value);
        }}
      />
    </div>
  );
};

export default DateTimePicker;
