import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import classNames from 'classnames';
import { Button, Table, Responsive } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';

import FETCH_VISITORS from 'graphql/queries/fetchVisitors.graphql';

import theme from './theme.scss';

type VisitorsQueryResponse = {
  visitors: {
    id: string;
    name: string;
    visitingFrom: string;
    phoneNumber: string;
    email: string;
    visitTime: string;
  }[];
};

type VisitorTableProps = {
  onVisitorSelect: (visitorId: string) => void;
};

const VisitorTable: FC<VisitorTableProps> = ({ onVisitorSelect }) => {
  const { loading, error, data } = useQuery<VisitorsQueryResponse>(FETCH_VISITORS);

  if (loading) {
    return (
      <div className={theme.default}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={theme.default}>
        <ErrorDisplay />
      </div>
    );
  }

  const visitors = data.visitors;

  return (
    <div className={theme.visitorTable}>
      <Button className={classNames(theme.filter, theme.active)}>All Time</Button>
      {/*
      <Button className={theme.filter}>Today</Button>
      <Button className={theme.filter}>Past Week</Button>
      <Button className={theme.filter}>Upcoming</Button>
      */}
      <Table unstackable compact className={theme.itemTable}>
        <Table.Header className={theme.itemHeader}>
          <Table.Row>
            <Table.HeaderCell width="3">NAME</Table.HeaderCell>
            <Responsive as={Table.HeaderCell} width="3" minWidth={992}>
              PHONE
            </Responsive>
            <Responsive as={Table.HeaderCell} width="3" minWidth={992}>
              EMAIL
            </Responsive>
            <Table.HeaderCell width="3">FROM</Table.HeaderCell>
            <Table.HeaderCell width="4">DATE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={theme.itemBody}>
          {visitors.map(visitor => (
            <Table.Row
              key={visitor.id}
              className={theme.itemRow}
              onClick={() => onVisitorSelect(visitor.id)}
            >
              <Table.Cell width="3">{visitor.name}</Table.Cell>
              <Responsive as={Table.Cell} width="3" minWidth={992}>
                {visitor.phoneNumber}
              </Responsive>
              <Responsive as={Table.Cell} width="3" minWidth={992}>
                {visitor.email}
              </Responsive>
              <Table.Cell width="3">{visitor.visitingFrom}</Table.Cell>
              <Table.Cell width="4">
                {moment(visitor.visitTime).format('HH:mm a DD MMMM YYYY')}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {visitors && visitors.length > 0 ? null : (
        <div className={theme.emptyMessage}>There are no visitor entries to show.</div>
      )}
    </div>
  );
};

export default VisitorTable;
