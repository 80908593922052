import React, { FC, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Divider, Modal } from 'semantic-ui-react';
import classNames from 'classnames';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';
import FETCH_VISITOR_DETAIL from 'graphql/queries/fetchVisitorDetail.graphql';

import theme from './theme.scss';

type VisitorQueryResponse = {
  visitor: {
    id: string;
    name: string;
    description: string;
    visitingFrom: string;
    phoneNumber: string;
    email: string;
    visitTime: string;
    verificationHistory: {
      verifiedAt: string;
      verifiedBy: {
        fullName: string;
      };
    }[];
    otp: number;
    status: string;
  };
};

type VisitorQueryVariables = {
  id: string;
};

type VisitorDetailProps = {
  visitorId: string;
  onClose: () => void;
};

type VisitorInformationProps = {
  visitor: {
    name: string;
    description: string;
    visitingFrom: string;
    phoneNumber: string;
    email: string;
    visitTime: string;
    status: string;
  };
};

type VerificationHistoryProps = {
  verificationHistory: {
    verifiedAt: string;
    verifiedBy: {
      fullName: string;
    };
  }[];
};

const sections = [
  {
    key: 'information',
    name: 'Details',
    emoji: '📋',
  },
  {
    key: 'verificationHistory',
    name: 'Activity',
    emoji: '🕘',
  },
];

const VisitorInformation: FC<VisitorInformationProps> = ({ visitor }) => {
  return (
    <div className={theme.information}>
      <div className={theme.field}>
        <p className={theme.label}>NAME</p>
        <p className={theme.value}>{visitor.name}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>PURPOSE / DESCRIPTION</p>
        <p className={theme.value}>{visitor.description}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>VISITING FROM</p>
        <p className={theme.value}>{visitor.visitingFrom}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>PHONE NUMBER</p>
        <p className={theme.value}>{visitor.phoneNumber}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>EMAIL</p>
        <p className={theme.value}>{visitor.email}</p>
      </div>
      <Divider />
      <div className={theme.field}>
        <p className={theme.label}>APPOINTMENT TIME</p>
        <p className={theme.value}>{visitor.visitTime}</p>
      </div>
      <div className={theme.field}>
        <p className={theme.label}>STATUS</p>
        <p className={theme.value}>{visitor.status}</p>
      </div>
    </div>
  );
};

const VerificationHistory: FC<VerificationHistoryProps> = ({ verificationHistory }) => {
  if (!verificationHistory || verificationHistory.length === 0) {
    return (
      <div className={theme.information}>
        <h3>Verification History</h3>
        <p>This visitor has not been verified yet.</p>
      </div>
    );
  }

  return (
    <div className={theme.information}>
      <h3>Verification History</h3>
      {verificationHistory.map(item => (
        <React.Fragment>
          <div className={theme.field} key={`verification-history-${item.verifiedAt}`}>
            <p>{item.verifiedBy.fullName}</p>
            <p>{item.verifiedAt}</p>
          </div>
          <Divider />
        </React.Fragment>
      ))}
    </div>
  );
};

const VisitorDetailModal: FC<VisitorDetailProps> = ({ visitorId, onClose }) => {
  const { loading, error, data } = useQuery<VisitorQueryResponse, VisitorQueryVariables>(
    FETCH_VISITOR_DETAIL,
    {
      variables: { id: visitorId },
    }
  );

  const [sectionOnDisplay, setSectionOnDisplay] = useState(sections[0].key);

  function renderContent() {
    if (loading) return <Loader />;

    if (error || !data) return <ErrorDisplay />;

    const visitor = data.visitor;

    return (
      <div className={theme.content}>
        <div className={theme.navigation}>
          <p className={theme.modalName}>VISITOR</p>
          {sections.map(section => (
            <div
              className={classNames(
                theme.sectionSelect,
                section.key === sectionOnDisplay ? theme.active : null
              )}
              onClick={() => setSectionOnDisplay(section.key)}
              key={`section-select-${section.key}`}
            >
              <span role="img" aria-label="notepad">
                {section.emoji}
              </span>{' '}
              <span className={theme.sectionName}>{section.name}</span>
            </div>
          ))}
        </div>

        {sectionOnDisplay === 'information' ? <VisitorInformation visitor={visitor} /> : null}

        {sectionOnDisplay === 'verificationHistory' ? (
          <VerificationHistory verificationHistory={visitor.verificationHistory} />
        ) : null}
      </div>
    );
  }

  return (
    <Modal open={true} size="small" className={theme.visitorDetailModal} onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};

export default VisitorDetailModal;
