import React, { FC, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Message, Modal } from 'semantic-ui-react';
import moment from 'moment';

import DateTimePicker from 'components/DateTimePicker';
import ValidatedInput from 'components/ValidatedInput';

import FETCH_VISITORS from 'graphql/queries/fetchVisitors.graphql';
import INVITE_VISITOR from 'graphql/mutations/inviteVisitor.graphql';

import theme from './theme.scss';

type Visitor = {
  id: string;
  name: string;
  description: string;
  visitingFrom: string;
  phoneNumber: string;
  email: string;
  visitTime: string;
  status: string;
};

type InviteVisitorMutationResponse = {
  visitor: Visitor;
};

type InviteVisitorMutationVariables = {
  userId: string;
  organizationId: string;
  name: string;
  description: string;
  visitingFrom: string;
  phoneNumber: string;
  email: string;
  visitTime: string;
};

type InviteVisitorModalProps = {
  onClose: () => void;
};

const InviteVisitorModal: FC<InviteVisitorModalProps> = ({ onClose }) => {
  const [inviteVisitor, { loading, error }] = useMutation(INVITE_VISITOR, {
    update(cache, { data: { inviteVisitor } }) {
      const fetchVisitorsQuery = cache.readQuery<{ visitors: Visitor[] }>({
        query: FETCH_VISITORS,
      });
      if (!fetchVisitorsQuery) return;
      const visitors = fetchVisitorsQuery.visitors;
      cache.writeQuery({
        query: FETCH_VISITORS,
        data: {
          visitors: visitors.concat([inviteVisitor]),
        },
      });
    },
  });

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [visitingFrom, setVisitingFrom] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [visitTime, setVisitTime] = useState(moment().set({ h: 10, m: 0, s: 0 }));

  function onSubmit() {
    inviteVisitor({
      variables: {
        name,
        description,
        visitingFrom,
        phoneNumber,
        email,
        visitTime,
      },
    }).then(() => onClose());
  }

  function renderContent() {
    return (
      <div className={theme.content}>
        <div className={theme.information}>
          <h3>Invite a Visitor</h3>
          <div className={theme.field}>
            <p className={theme.label}>NAME</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={name}
              onValueChange={value => setName(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>PURPOSE / DESCRIPTION</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={description}
              onValueChange={value => setDescription(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>APPOINTMENT TIME</p>
            <DateTimePicker value={visitTime} onValueChange={value => setVisitTime(value)} />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>VISITING FROM</p>
            <ValidatedInput
              validator="shortText"
              required={true}
              value={visitingFrom}
              onValueChange={value => setVisitingFrom(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>PHONE NUMBER</p>
            <ValidatedInput
              validator="phonenumber"
              required={true}
              value={phoneNumber}
              onValueChange={value => setPhoneNumber(value)}
            />
          </div>
          <div className={theme.field}>
            <p className={theme.label}>EMAIL</p>
            <ValidatedInput
              validator="email"
              required={true}
              value={email}
              onValueChange={value => setEmail(value)}
            />
          </div>
          {error ? <Message error header="An error occured. Please try again later." /> : null}
          <Button primary loading={loading} onClick={() => onSubmit()}>
            Submit
          </Button>
          <Button basic disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal open={true} className={theme.inviteVisitorModal}>
      {renderContent()}
    </Modal>
  );
};

export default InviteVisitorModal;
