import React, { FC, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import VisitorTable from './VisitorTable';
import VisitorDetailModal from './VisitorDetailModal';
import InviteVisitorModal from './InviteVisitorModal';

import Title from 'components/Title';
import ActionPanel from 'components/ActionPanel';

const Visitors: FC = () => {
  const [visitorOnDisplay, setVisitorOnDisplay] = useState<string>('');
  const [openInviteModal, toggeInviteModal] = useState<boolean>(false);

  return (
    <AdminLayout>
      <div>
        <Title name="Visitors" backButtonRoute="/" />
        <Divider />
        <h2>QUICK LINKS</h2>
        <ActionPanel>
          <ActionPanel.Action
            name={'Invite Visitor'}
            type="callback"
            callback={() => toggeInviteModal(true)}
          />
        </ActionPanel>
        <Divider />
        <h2>HISTORY</h2>

        <VisitorTable onVisitorSelect={visitorId => setVisitorOnDisplay(visitorId)} />

        {!!visitorOnDisplay ? (
          <VisitorDetailModal
            visitorId={visitorOnDisplay}
            onClose={() => setVisitorOnDisplay('')}
          />
        ) : null}

        {openInviteModal ? <InviteVisitorModal onClose={() => toggeInviteModal(false)} /> : null}
      </div>
    </AdminLayout>
  );
};

export default Visitors;
