import React, { FC } from 'react';
import { Divider } from 'semantic-ui-react';

import AdminLayout from 'layouts/AdminLayout';
import Title from 'components/Title';

import theme from './theme.scss';

const Cameras: FC = () => {
  return (
    <AdminLayout>
      <div className={theme.cameras}>
        <Title name="Cameras" backButtonRoute="/" />
        <Divider />
        <p>You have no cameras installed yet.</p>
      </div>
    </AdminLayout>
  );
};

export default Cameras;
